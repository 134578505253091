













import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { BadgeStores } from '@/store/badges';
import BadgeForm from '@/components/badges/BadgeForm.vue';
import { omit } from 'lodash';

@Component({
  components: {
    Page,
    BackButtonLayout,
    BadgeForm,
  },
})
export default class EditBadges extends Vue {
  badgeStore = BadgeStores;

  get badge() {
    return this.badgeStore?.badge;
  }

  mounted() {
    this.badgeStore.getBadge(Number(this.$route.params.id));
  }

  handleSubmit(payload: any) {
    this.badgeStore.updateBadge({
      id: Number(this.$route.params.id),
      ...(omit(payload, ['timecreated', 'timemodified']) as any),
    });
  }
}
